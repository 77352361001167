import { useQuery } from '@tanstack/vue-query'
import dayjs from 'dayjs'
import { useToast } from '~/components/ui/toast'
import CONSTANTS, { SESSION_STORAGE_KEYS } from '~/constants/CONSTANTS'
import { generateSixDigitNumber } from '~/lib/utils'
import { useAuthStore } from '~/stores/auth'
import type {
  MakeBulkBillsPaymentWithCardPayPayload,
  MakeBulkBillsPaymentWithCreditFacilityPayload,
  MakeBulkBillsPaymentWithOpenBankingPayload,
  MakeSingleBillPaymentWithCardPayPayload,
  MakeSingleBillPaymentWithCreditFacilityPayload,
  MakeSingleBillPaymentWithOpenBankingPayload,
} from '~/types/apiPayload/bills.payload'
import type {
  MakePayrollPaymentPayloadWithCardPayPayload,
  MakePayrollPaymentPayloadWithOpenBankingPayload,
} from '~/types/apiPayload/payroll.payload'

type SessionData = {
  date: Date
  digit: string | number
}

const CardPay = {
  checkIfSessionDataExistOrValid() {
    const serializedSessionData = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SESSION_CODE,
    )

    if (!serializedSessionData) return false
    const sessionData = JSON.parse(serializedSessionData)

    const sessionDataDate = dayjs(sessionData.date)
    const now = dayjs(new Date())

    const diff = now.diff(sessionDataDate, 'minute')

    if (diff > 15) return false

    return true
  },

  // getStoredPin() {
  //   return sessionStorage.getItem(SESSION_STORAGE_KEYS.CURRENT_PIN)
  // },

  // setPin(pin: string | number) {
  //   return sessionStorage.setItem(
  //     SESSION_STORAGE_KEYS.CURRENT_PIN,
  //     pin.toString(),
  //   )
  // },

  getSessionData() {
    const serializedSessionData = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SESSION_CODE,
    )

    if (serializedSessionData) {
      return JSON.parse(serializedSessionData) as SessionData
    }
    return null
  },

  clearSessionStorage() {
    sessionStorage.removeItem('lenkie-count')
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SESSION_CODE)
    sessionStorage.removeItem('wax-p')
  },

  async generateSessionCode(callback?: () => void) {
    const { toast } = useToast()
    try {
      const { $api } = useNuxtApp()
      const { personId } = storeToRefs(useAuthStore())

      const sixDigits = generateSixDigitNumber()

      const sessionData: SessionData = {
        date: new Date(),
        digit: sixDigits,
      }

      const { status } = await $api.core.twoFactorAuthenticator.send2FA({
        user_id: personId.value as string,
        operation: 'PaymentRequest',
        session_code: sixDigits,
        to: null,
      })

      if (status === 200) {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.SESSION_CODE,
          JSON.stringify(sessionData),
        )

        toast({
          title: 'OTP Request',
          description: 'OTP has been sent!',
        })

        if (callback) {
          callback()
        }
      }
    } catch (error) {
      toast({
        title: 'Error',
        variant: 'destructive',
        description:
          'An error occurred generating one time password, please try again later',
      })
    }
  },

  async validate2fa(pin?: string) {
    const { $api } = useNuxtApp()
    const { personId } = storeToRefs(useAuthStore())

    const sessionData = CardPay.getSessionData()
    if (!sessionData) return false

    const { data: response } =
      await $api.core.twoFactorAuthenticator.validate2fa({
        otp: pin || null,
        session_type: 'Payment',
        session_code: sessionData.digit,
        user_id: personId.value as string,
      })

    if (
      !response.result.has_validated2fa ||
      !response.result.has_active_session
    )
      return false
    else {
      return true
    }
  },

  makeBulkBillsPaymentWithCardPay(
    payload: MakeBulkBillsPaymentWithCardPayPayload,
  ) {
    const { $api } = useNuxtApp()
    return $api.banking.bills.makeBulkBillPayment(payload)
  },

  makeSingleBillPaymentWithCardPay(
    payload: MakeSingleBillPaymentWithCardPayPayload,
  ) {
    const { $api } = useNuxtApp()
    return $api.banking.bills.makeSingleBillPayment(payload)
  },

  makePayrollPaymentWithCardPay(
    payload: MakePayrollPaymentPayloadWithCardPayPayload,
  ) {
    const { $api } = useNuxtApp()
    return $api.banking.payroll.makePayrollPayment(payload)
  },
}

const Openbanking = {
  async makePayrollPaymentWithOpenBanking(
    payload: MakePayrollPaymentPayloadWithOpenBankingPayload,
  ) {
    const { $api, $event, $config } = useNuxtApp()
    const { toast } = useToast()

    const profileStore = useProfileStore()

    try {
      const { status, data } =
        await $api.banking.payroll.makePayrollPayment(payload)

      if (data.result.open_bank_payment_response) {
        const { id, resource_token } = data.result.open_bank_payment_response

        if (status === 200) {
          Openbanking.redirectToTrueLayerToCompletePayment({
            id,
            resource_token,
            redirectURI:
              $config.public.OPEN_BANKING_PAYROLL_PAYMENT_REDIRECT_URL,
          })
        }
      }
    } catch (error: any) {
      const failureReasons: unknown = error?.response?.data?.failure_reasons

      let toastErrorMessage
      if (failureReasons) {
        const failureReasonKeys = Object.keys(failureReasons)

        if (failureReasonKeys.length > 0) {
          const key = failureReasonKeys[0] // Get the first failure reason key

          // Get the error message for the first failure reason, or use a default message if not available
          const errorMessage =
            failureReasons[key] ||
            'We could not initiate/complete your payment, please try again'

          // Set the error message for displaying to the user
          toastErrorMessage = errorMessage

          // Track the payment failure event using Mixpanel, providing the reason as data
          $event('track:mixpanel', {
            event: 'Open Banking Payment Failed',
            data: { reason: errorMessage },
          })

          // Report the error, including company details and the error message
          $event(
            'error:report',
            JSON.stringify(
              `Company Id: ${profileStore?.data?.organisation?.id}, Company Name: ${profileStore?.data?.organisation?.trading_name} errors: ${errorMessage}`,
            ),
          )
        }
      }

      toast({
        title: 'Payment Failed',
        variant: 'destructive',
        description: toastErrorMessage,
      })
    }
  },

  async makeBulkBillsPaymentWithOpenBanking(
    payload: MakeBulkBillsPaymentWithOpenBankingPayload,
  ) {
    const { $api, $event, $config } = useNuxtApp()
    const { toast } = useToast()
    const profileStore = useProfileStore()

    const {
      bill_ids: billIds,
      organisation_id,
      bank_account: bankAccount,
      person_id: personId,
    } = payload

    const requestBody: MakeBulkBillsPaymentWithOpenBankingPayload = {
      bill_ids: billIds,
      organisation_id,
      payment_method: 'OpenBanking',
      bank_account: bankAccount,
      person_id: personId,
      card_payment_method_id: null,
      loan_pricing: null,
    }

    try {
      const { data, status } =
        await $api.banking.bills.makeBulkBillPayment(requestBody)

      if (status === 200) {
        if (data.result?.resource_token && data.result.id) {
          const { id, resource_token } = data.result

          Openbanking.redirectToTrueLayerToCompletePayment({
            id,
            resource_token,
            redirectURI: $config.public.OPEN_BANKING_PAYMENT_REDIRECT_URI,
          })
        }
      }
    } catch (error: any) {
      const failureReasons: unknown = error?.response?.data?.failure_reasons

      let toastErrorMessage
      if (failureReasons) {
        const failureReasonKeys = Object.keys(failureReasons)

        if (failureReasonKeys.length > 0) {
          const key = failureReasonKeys[0] // Get the first failure reason key

          // Get the error message for the first failure reason, or use a default message if not available
          const errorMessage =
            failureReasons[key] ||
            'We could not initiate/complete your payment, please try again'

          // Set the error message for displaying to the user
          toastErrorMessage = errorMessage

          // Track the payment failure event using Mixpanel, providing the reason as data
          $event('track:mixpanel', {
            event: 'Open Banking Payment Failed',
            data: { reason: errorMessage },
          })

          // Report the error, including company details and the error message
          $event(
            'error:report',
            JSON.stringify(
              `Company Id: ${profileStore?.data?.organisation?.id}, Company Name: ${profileStore?.data?.organisation?.trading_name} errors: ${errorMessage}`,
            ),
          )
        }
      }

      toast({
        title: 'Payment Failed',
        variant: 'destructive',
        description: toastErrorMessage,
      })
    }
  },

  async makeSingleBillsPaymentWithOpenBanking(
    payload: MakeSingleBillPaymentWithOpenBankingPayload,
  ) {
    const { $api, $event, $config } = useNuxtApp()
    const { toast } = useToast()
    const profileStore = useProfileStore()

    const requestBody: MakeSingleBillPaymentWithOpenBankingPayload = {
      ...payload,
      payment_type: 'OpenBank',
    }

    try {
      const { data, status } =
        await $api.banking.bills.makeSingleBillPayment(requestBody)

      if (status === 200) {
        if (data.result?.resource_token) {
          const { id, resource_token } = data.result

          Openbanking.redirectToTrueLayerToCompletePayment({
            id,
            resource_token,
            redirectURI: $config.public.OPEN_BANKING_PAYMENT_REDIRECT_URI,
          })
        }
      }
    } catch (error: any) {
      const failureReasons: unknown = error?.response?.data?.failure_reasons

      let toastErrorMessage
      if (failureReasons) {
        const failureReasonKeys = Object.keys(failureReasons)

        if (failureReasonKeys.length > 0) {
          const key = failureReasonKeys[0] // Get the first failure reason key

          // Get the error message for the first failure reason, or use a default message if not available
          const errorMessage =
            failureReasons[key] ||
            'We could not initiate/complete your payment, please try again'

          // Set the error message for displaying to the user
          toastErrorMessage = errorMessage

          // Track the payment failure event using Mixpanel, providing the reason as data
          $event('track:mixpanel', {
            event: 'Open Banking Payment Failed',
            data: { reason: errorMessage },
          })

          // Report the error, including company details and the error message
          $event(
            'error:report',
            JSON.stringify(
              `Company Id: ${profileStore?.data?.organisation?.id}, Company Name: ${profileStore?.data?.organisation?.trading_name} errors: ${errorMessage}`,
            ),
          )
        }
      }

      toast({
        title: 'Payment Failed',
        variant: 'destructive',
        description: toastErrorMessage,
      })
    }
  },

  redirectToTrueLayerToCompletePayment({
    id,
    resource_token,
    redirectURI,
  }: {
    id: string
    resource_token: string
    redirectURI: string
  }) {
    const { $config } = useNuxtApp()
    const { toast } = useToast()

    const TRUELAYER_PAYMENT = $config.public.TRUELAYER_PAYMENT

    toast({
      title: 'Redirecting....',
      variant: 'default',
      description:
        'Please wait while we redirect you to truelayer to make payment',
    })

    const url = `${TRUELAYER_PAYMENT}/payments#payment_id=${id}&resource_token=${resource_token}&return_uri=${redirectURI}`
    window.location.replace(url)
  },
}

const CreditFacility = {
  makeBulkBillsPaymentWithCreditFacility(
    payload: MakeBulkBillsPaymentWithCreditFacilityPayload,
  ) {
    const { $api } = useNuxtApp()
    return $api.banking.bills.makeBulkBillPayment(payload)
  },

  makeSingleBillPaymentWithCreditFacility(
    payload: MakeSingleBillPaymentWithCreditFacilityPayload,
  ) {
    const { $api } = useNuxtApp()
    return $api.banking.bills.makeSingleBillPayment(payload)
  },
}

export default function usePaymentsComposable() {
  const { $api } = useNuxtApp()
  const { organisationId } = storeToRefs(useProfileStore())
  const { isLoggedIn } = storeToRefs(useAuthStore())

  const { data: cardFeeData } = useQuery({
    queryKey: ['get-card-payment-fees'],
    queryFn: $api.banking.cardPayments.getCardPaymentFees,
    select: (data) => data.data,
    enabled: computed(() => !!organisationId.value && isLoggedIn.value),
  })

  const cardFee = computed(() => {
    return cardFeeData.value?.fee || CONSTANTS.CARD_PAYMENT_FEES
  })

  const calculateAmountsForCardPay = function (amount: number) {
    const base_amount = amount
    const processing_fee = base_amount * cardFee.value
    const total_amount = base_amount + processing_fee
    return {
      base_amount,
      processing_fee,
      total_amount,
    }
  }

  return {
    CardPay: { ...CardPay, cardFee, calculateAmountsForCardPay },
    Openbanking,
    CreditFacility,
  }
}
