<template>
  <Dialog :open="props.open" @update:open="closeModal">
    <DialogContent
      class="z-[400000000] max-w-[550px] border-none bg-transparent shadow-none outline-none ring-0"
    >
      <DialogClose
        class="absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-secondary"
      >
        <X class="h-4 w-4" />
        <span class="sr-only">Close</span>
      </DialogClose>
      <div class="m-3 rounded-lg border bg-white px-7 pb-12 pt-6">
        <div>
          <img
            class="mx-auto rounded-full bg-[#E7EEFF] p-3"
            src="@/assets/svgs/p2fa.svg"
          />
        </div>
        <div class="mt-4 text-center">
          <DialogTitle as="h2" class="font-bold text-primary"
            >Enter one time password</DialogTitle
          >
          <DialogDescription class="mt-2 text-center text-sm text-[#667085]">
            {{}}
            Enter 6-digit code sent to {{ selected === 'Email' || !selected  ?  profile.work_email_address : profile.work_phone_number  }}
          </DialogDescription>

          <opt-input
            :fields="fields"
            :loading="loading"
            :error="errors"
            @update:model-value="submit($event)"
          >
            <Button
              :disabled="loading || otp.length !== 6"
              variant="default"
              class="mt-4 w-full"
              @click="emitPin"
            >
              <ph-spinner
                v-if="loading"
                :size="24"
                class="mr-2 animate-spin duration-1000"
              />
              {{ loading ? 'Completing payment...' : 'Continue' }}</Button
            >
          </opt-input>
        </div>
        <!-- <p class="text-red-400 text-sm font-medium text-center">An error occurred please try again later {{ props.errorMessage }}</p> -->
      </div>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { X } from 'lucide-vue-next'
import { PhSpinner } from '@phosphor-icons/vue'
import OptInput from '@/components/shared/opt-input.vue'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogClose,
} from '@/components/ui/dialog'

const fields = ref(6)
const otp = ref('')
const selected = ref()

const auth = useAuthStore()

const props = defineProps<{
  open: boolean
  loading: boolean
}>()

const emit = defineEmits(['update:getPin', 'close'])

const errors = ref('')

const userProfile = useProfileStore()

const profile = computed(() => userProfile.profile)
const { personId } = storeToRefs(auth)

const { $lenkieApi } = useNuxtApp()

const submit = ($event: string) => {
  otp.value = $event
}

const closeModal = () => {
  const { $event } = useNuxtApp()
  $event('close:modal', { trigger: true })
  emit('close')
}

const emitPin = () => {
  if (otp.value.length === 6) {
    emit('update:getPin', otp.value)
  }
}

const getChannel = async () => {
  try {
    const { data } = await $lenkieApi.get(
      `/TwoFactorAuthenticator/2fa-channel`,
      {
        params: {
          userId: personId.value,
        },
      },
    )
    selected.value = data?.result?.channels[0]
  } catch (error) {}
}

onMounted(() => {
  getChannel()
})
</script>
